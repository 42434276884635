import $ from 'jquery';

function labelActive(el) {
  let inputId = el.attr('id');
  $(`label[for=${inputId}]`).addClass('active');
}

function labelInActive(el) {
  let inputId = el.attr('id');
  if(!el.val()) $(`label[for=${inputId}]`).removeClass('active');
  if (el.val() === '(___) ___-____' ) {
    $(`label[for=${inputId}]`).removeClass('active');
  }
}

function initLabel() {
    if ($('.sk-form-gravity').length > 0) {
        $(document).on('focus','input', function() {
          labelActive($(this));
        });
          
        $(document).on('focusout','input', function() {
          labelInActive($(this));
        });
          
        $(document).on('focus','textarea', function() {
          labelActive($(this));
        });
          
        $(document).on('focusout','textarea', function() {
          labelInActive($(this));
        });

        /* eslint-disable */
        jQuery(document).on('gform_page_loaded', function (event, form_id, current_page) {
            let formId = `#gform_${form_id}`;
            jQuery(`${formId} .gfield_contains_required`).each(function () {
              if (!(jQuery(this).hasClass('gfield_error'))) {
                jQuery(this).addClass('gfield_req');
              }
            })
            jQuery(`${formId} .gfield input`).each(function () {
              if (jQuery(this).val()) {
                let inputId = jQuery(this).attr('id');
                $(`label[for=${inputId}]`).addClass('active');
                console.log(jQuery(this));
              }
            })
        });
        /* eslint-enable */
    }
}

export {
    initLabel,
}