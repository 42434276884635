import Scrollbar from 'smooth-scrollbar';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import AOS from 'aos'

gsap.registerPlugin(ScrollTrigger);

AOS.init({ disable: 'mobile'});

const animations = () => {
  let container = document.querySelector('#scroll-content');
  let scrollbar = Scrollbar.init(container);
  let header = document.querySelector('.header');
  let body = document.querySelector('body');
  let togglerMobileMenu = document.querySelector('.navbar-toggler');
  let mobileMenu = document.querySelector('.header__nav-mobile');
  let form = document.querySelector('.gform_wrapper.gravity-theme .gfield textarea');
  let url_string = window.location.href;
  let url = new URL(url_string);
  let anchor = url.searchParams.get('q');
  let trigger = url.searchParams.get('t');
  let mainContainer = document.querySelector('.sk-main');

  function scrollToLines() {
    let lines = document.querySelectorAll('.title-line')

    lines.forEach(function (el) {
      el.addEventListener('click', () => {
        let scrollToHere = el.parentElement.offsetTop;
        scrollbar.scrollTo(0, scrollToHere, 1000);
      });
    });
  }

  function scrollToTabs() {
    let tabs = document.querySelectorAll('.js-tab-link')
    var once = false;

    tabs.forEach(function (el) {
      el.addEventListener('click', () => {
        let scrollToHere = el.closest('.js-tabs').querySelector('.js-tabs-content').offsetTop;

        if (window.innerWidth < 1024 && el.closest('.activity__tabs-nav')) {
          scrollbar.scrollTo(0, scrollToHere, 1000);
        }else if (el.closest('.swiper-sevices')) {
          scrollbar.scrollTo(0, scrollToHere, 1000);
        }else if (window.innerWidth > 1024 && trigger && !once) {
          scrollToHere = document.querySelector('.activity').offsetTop
          scrollbar.scrollTo(0, scrollToHere, 1000);
          once = true;
        }
      });
    });
  }

  window.addEventListener('resize', scrollToTabs());
  window.addEventListener('onload', scrollToTabs());

  function graphicsAnimation() {
    let animationContainer = document.querySelectorAll('.block-logo')

    animationContainer.forEach(function (el) {
      let itemsGrahics = el.querySelectorAll('.block-logo__grahics .header-grahics')
      const images = [
        "url(" + themeVars.home + "/wp-content/themes/secra/public/img/SECRA_AnimationBlip_1.svg)",
        "url(" + themeVars.home + "/wp-content/themes/secra/public/img/SECRA_AnimationBlip_2.svg)",
        "url(" + themeVars.home + "/wp-content/themes/secra/public/img/SECRA_AnimationBlip_3.svg)",
        "url(" + themeVars.home + "/wp-content/themes/secra/public/img/SECRA_AnimationBlip_4.svg)",
        "url(" + themeVars.home + "/wp-content/themes/secra/public/img/SECRA_AnimationBlip_5.svg)",
        "url(" + themeVars.home + "/wp-content/themes/secra/public/img/SECRA_AnimationBlip_6.svg)",
      ];

      let random = Math.floor(Math.random() * images.length);
      let randomImage = images[random]

      itemsGrahics[Math.floor(Math.random() * itemsGrahics.length)].style.backgroundImage = randomImage;
    })
  }

  function scrollToTop() {
    const buttonTop = document.querySelector('.btn-up');
    buttonTop.addEventListener('click', function () {
      scrollbar.scrollTo(0, 0, 1000)
    })
  }

  function triggerClickAnchors(triggerElement) {
    triggerElement.click()
    mobileMenu.classList.remove('open-mobile-menu')
    scrollbar.updatePluginOptions('disableScroll', { open: false });
    body.classList.remove('disabled')
    togglerMobileMenu.classList.remove('close-menu')
  }

  function triggerScollAnchors(scrollToHere) {
    scrollbar.scrollTo(0, scrollToHere, 1000);
  }

  function anchorLinks() {
    let links = document.querySelectorAll('a[href^="#"]')

    links.forEach(function (el) {
      if (el.getAttribute('href') == '#') {
        el.addEventListener('click', function (e) {
          e.preventDefault()
        })
      } else {
        el.addEventListener('click', () => {
          let href = el.getAttribute('href');
          let anchor = document.querySelector(href);
          let trigger = el.getAttribute('data-target');
          let triggerElement = document.querySelector("a[href='" + trigger + "']");

          if (anchor && el.closest('.js-tabs-nav') === null) {
            triggerScollAnchors(anchor.offsetTop)
            mobileMenu.classList.remove('open-mobile-menu')
            scrollbar.updatePluginOptions('disableScroll', { open: false });
            body.classList.remove('disabled')
            togglerMobileMenu.classList.remove('close-menu')
          }

          if (triggerElement && anchor) {
            triggerScollAnchors(anchor.offsetTop)
            triggerClickAnchors(triggerElement)
          }

          if (anchor === null && triggerElement === null && el.closest('.header_inner')) {
            let anchor = el.getAttribute('href').substring(1)
            let target = el.getAttribute('data-target')
            let scrollTo = document.querySelector('#' + anchor)

            if (scrollTo) {
              triggerScollAnchors(scrollTo.offsetTop)
            } else {
              if (target) {
                window.location.replace(themeVars.home + "?q=" + anchor +  "&t=" + target.substring(1));
              } else {
                window.location.replace(themeVars.home + "?q=" + anchor);
              }
            }
          }
        });
      }
    });
  }

  function initAnchorHash() {
    let triggerElement = document.querySelector("a[href='#" + trigger + "']");

    if (trigger) {
      triggerClickAnchors(triggerElement)
      scrollToTabs()
    }

    if (anchor && triggerElement === null) {
      let scrollTo = document.querySelector('#' + anchor)
      triggerScollAnchors(scrollTo.offsetTop)
    }
  }

  function heroAnimation() {
    let lastScrollTop = 0;
    let isScrolling = false;
    let hero = document.querySelectorAll('.hero');
    let timeScale = 1

    if (hero.length == 0) {
      return;
    }

    setInterval(() => {
      isScrolling = false;
    }, 250);

    scrollbar.addListener((s) => {
      let st = s.offset.y;
      if (st > lastScrollTop) {
        gsap.to([roll1], {
          timeScale: 1,
          overwrite: true,
        })
        isScrolling = true;
        gsap.ticker.add(() => {
          timeScale = isScrolling ? 2 : 1;
          roll1.timeScale(timeScale);
        });
      } else {
        gsap.to([roll1], {
          timeScale: -1,
          overwrite: true,
        })
        isScrolling = true;
        gsap.ticker.add(() => {
          timeScale = isScrolling ? -2 : -1;
          roll1.timeScale(timeScale);
        });
      }
      lastScrollTop = st;
    });

    const roll1 = roll('.hero__title', {
      duration: 60,
    })

    function roll(targets, vars, reverse) {
      vars = vars || {};
      vars.ease || (vars.ease = 'none');
      const tl = gsap.timeline({
          repeat: -1,
          onReverseComplete() {
            this.totalTime(this.rawTime() + this.duration() * 10);
          },
        }),
        elements = gsap.utils.toArray(targets),
        clones = elements.map(el => {
          let clone = el.cloneNode(true);
          el.parentNode.appendChild(clone);
          return clone;
        }),

        positionClones = () => elements.forEach((el, i) =>
          gsap.set(clones[i], {
            position: "absolute",
            overwrite: false,
            top: el.offsetTop,
            left: el.offsetLeft + (reverse ? -el.offsetWidth : el.offsetWidth),
          }));
      positionClones();
      elements.forEach((el, i) => tl.to([el, clones[i]], {
        xPercent: reverse ? 100 : -100,
        ...vars,
      }, 0));
      window.addEventListener("resize", () => {
        let time = tl.totalTime();
        tl.totalTime(0);
        positionClones();
        tl.totalTime(time);
      });
      return tl;
    }
  }

  function initNativeScrollbar() {
    [
      'touchmove',
      'mousewheel',
      'wheel',
    ].forEach((eventType) => {
      form.addEventListener(eventType, (e) => e.stopPropagation());
    });
  }

  function initStickyButton() {
    let stickyButton = document.querySelector('.hero__link');

    if (typeof (stickyButton) != 'undefined' && stickyButton != null && window.innerWidth > 992) {

      let stickyButtonTop = stickyButton.offsetTop + header.offsetHeight - 25;
      let maxTop = mainContainer.offsetHeight - stickyButton.offsetHeight - 50;

      scrollbar.addListener((s) => {
        let st = s.offset.y;
        if (st >= stickyButtonTop && st <= maxTop) {
          stickyButton.style.top = st - stickyButtonTop + 'px';
        }
      })
    }
  }

  window.addEventListener('resize', function () {
    initStickyButton()
  })

  function AOSinit() {
    [].forEach.call(document.querySelectorAll('[data-aos]'), (el) => {
      scrollbar.addListener(() => {
        if (scrollbar.isVisible(el)) {
          setTimeout(() => {
            el.classList.add('aos-animate')
          }, 500);
        }
      });
    });
  }

  return {
    init() {
      heroAnimation();
      scrollToLines();
      setInterval(function () {
        graphicsAnimation();
      }, 1000);
      scrollToTop();
      setTimeout(() => {
        anchorLinks();
      }, 1000);
      initNativeScrollbar();
      initStickyButton();
      AOSinit();
      initAnchorHash();
    },
  }
}

export {
  animations,
}
