import $ from 'jquery';

const tabs = () => {
  let tabContainer = $('.js-tabs');

  tabContainer.each(function() {
    let tabContent = $(this).find('.js-tabs-content > .js-tab-content');
    let tabLinks = $(this).find('.js-tabs-nav .js-tab-link');
    let parentClass = $(this).parent().attr('class')

    function showActive () {
      tabContent.hide().filter(':first').show();
      tabLinks.filter(':first').addClass('active');
    }

    function generateIds() {
      tabContent.each(function(index) {
        index++;
        $(this).attr("id", 'tab' + '-'+ parentClass + '-' + index);
      });
    }

    function generateAnchors() {
      tabLinks.each(function(index) {
        index++;
        $(this).attr("href", '#tab' + '-'+ parentClass + '-' + index);
      });
    }

    function createTabs() {
      tabLinks.on('click', function(){
        tabLinks.removeClass('active');
        $(this).addClass('active');
        tabContent.hide();
        tabContent.filter(this.hash).fadeIn({duration:1000, easing:"swing"});
        return false;
      })
    }

    showActive();
    generateIds();
    generateAnchors();
    createTabs();
  })
}

export {
  tabs,
}
