import Swiper, { Navigation, Autoplay, Pagination, EffectFade } from "swiper";
Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);
import 'swiper/swiper-bundle.css';

const slider = () => {
  function initSliderMap() {
    const point = document.getElementById('map-point');
    let mapSlider = document.querySelectorAll('.swiper-map.swiper');
    if (mapSlider.length > 0) {
      mapSlider.forEach((element) => {
        new Swiper(element, {
          slidesPerView: '1',
          speed: 2000,
          effect: 'fade',
          loop: true,
          autoplay: {
            delay: 2000,
          },
        });

        element.swiper.on('slideChange', function () {
          let activeSlide = element.swiper.slides[element.swiper.activeIndex];
          let left = activeSlide.dataset.left + '%';
          let top = activeSlide.dataset.top + '%';
          point.style.left = left;
          point.style.top = top;
        });
      });
    }
  }

  function initSliderHero() {
    let heroSlider = document.querySelectorAll('.hero__slider.swiper');
    if (heroSlider.length > 0) {
      heroSlider.forEach((element) => {
        new Swiper(element, {
          slidesPerView: '1',
          speed: 3000,
          effect: 'fade',
          loop: true,
          autoplay: {
            delay: 3000,
          },
        });
      });
    }
  }

  function initSliderPresentation() {
    let presentationSlider = document.querySelectorAll('.swiper-presentation.swiper');
    if (presentationSlider.length > 0) {
      presentationSlider.forEach((element) => {
        new Swiper(element, {
          slidesPerView: '1',
          speed: 1000,
          loop: true,
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true,
          },
          autoplay: {
            delay: 5000,
          },
        });
      });
    }
  }

  return {
    init() {
      initSliderMap();
      // sliderOnResize();
      initSliderHero();
      initSliderPresentation();
    },
  }
}

export {
  slider,
}
