import $ from 'jquery';
import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar';

class ModalPlugin extends ScrollbarPlugin {
  static pluginName = 'disableScroll';

  static defaultOptions = {
    open: false,
  };

  transformDelta(delta) {
    return this.options.open ? { x: 0, y: 0 } : delta;
  }
}

Scrollbar.use(ModalPlugin, /* OverscrollPlugin */);

const header = () => {
  const body = $('body');
  const togglerMobileMenu = $('.navbar-toggler');
  const mobileMenu = $('.header__nav-mobile');
  const mobileMenuToggleSubmenu = $('.header__nav-mobile .menu-item-has-children > a')
  const toggleSubmenu = $('.header__nav-main ul .menu-item-has-children');
  const firstLiMenu = $('.header__nav-main > .nav-main > ul > .menu-item > a');
  const headerNavDesktop = $('.header__nav-main');
  const parentHasChildren = headerNavDesktop.find('.menu-item-has-children');
  let container = document.querySelector('#scroll-content');
  let scrollbar = Scrollbar.init(container);

  function stickyMobileNav() {
    let fixed = document.querySelector('.header__nav-mobile')
    scrollbar.addListener(({ offset }) => {
      fixed.style.top = offset.y + 'px';
      fixed.style.left = offset.x + 'px';
    });
  }

  function toggleMobileMenu() {
    togglerMobileMenu.on('click', function () {
      if($(this).hasClass('close-menu')) {
        scrollbar.updatePluginOptions('disableScroll', { open: false });
        mobileMenu.removeClass('open-mobile-menu')
        togglerMobileMenu.removeClass('close-menu')
      } else {
        scrollbar.updatePluginOptions('disableScroll', { open: true });
        mobileMenu.addClass('open-mobile-menu')
        togglerMobileMenu.addClass('close-menu')
      }
    })
  }

  function closeMenu() {
    $(document).on('click', function (e) {
      if ($(window).width() < 1024) {
        if (
          e.target.closest('.nav-mobile') === null &&
          e.target.closest('.navbar-toggler') === null
        ) {
          scrollbar.updatePluginOptions('disableScroll', { open: false });
          mobileMenu.removeClass('open-mobile-menu')
          body.removeClass('disabled')
          togglerMobileMenu.removeClass('close-menu')
        }
      }
    });
  }

  function toggleMobileSubmenu() {
    mobileMenuToggleSubmenu.on('click', function() {
      mobileMenuToggleSubmenu.not(this).next().slideUp(500)
      mobileMenuToggleSubmenu.not(this).removeClass('active-link')
      $(this).next().slideToggle(500)
      $(this).toggleClass('active-link')
    })
  }

  function showDesktopSubmenu() {
    toggleSubmenu.hover(
      function () {
        if($(this).find('.submenu').length > 0) {
          $(this).find('.submenu').addClass('active');
          $(this).find('a').first().addClass('active-link')
        }
      },
      function () {
        if($(this).find('.submenu').length > 0) {
          $(this).find('.submenu').removeClass('active');
          $(this).find('a').first().removeClass('active-link')
        }
      }
    )

    toggleSubmenu.on('click',
      function () {
        if($(this).find('.submenu').length > 0) {
          $(this).find('.submenu').toggleClass('active');
        }
      },
    )
  }

  function closeMenuOnResize() {
    (function () {
      $(window).on('resize', function () {
        if ($(window).width() > 1024) {
          mobileMenu.removeClass('open-mobile-menu')
          body.removeClass('disabled')
          togglerMobileMenu.removeClass('close-menu')
        }
      })
    })();
  }

  function showOnKeyboard() {
    firstLiMenu.focus(function() {
      if (!firstLiMenu.hasClass('menu-item-has-children')) {
        const subNavMenuActive = $('.submenu.active');
        if (subNavMenuActive) {
          subNavMenuActive.removeClass('active');
          // subNavMenuActive.slideToggle()
        }
      }
    })

    parentHasChildren.each(function() {
      const link = $(this).find('a');
      link.on('keypress', function (e) {
        if (e.key === ' ' || e.key === 'Spacebar') {
          e.preventDefault();
          const dropdown = $(this).next();
          if (dropdown.hasClass('active')) {
            dropdown.removeClass('active');
            dropdown.slideToggle()
          } else {
            link.blur();
            dropdown.addClass('active');
            dropdown.slideToggle()
            const firstLink = dropdown.find('a').first();
            setTimeout(function () {
              firstLink.focus();
            }, 100);
          }
        }
      });
    })
  }

  return {
    init() {
      toggleMobileMenu();
      closeMenu();
      toggleMobileSubmenu();
      showDesktopSubmenu();
      closeMenuOnResize();
      showOnKeyboard();
      stickyMobileNav();
    },
  }
}

export {
  header,
}
