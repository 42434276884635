import $ from 'jquery';

function initPreloader() {
  let body = $('body');
  let preloader = $('.loaded-wrapper')
  let version = Math.floor(Math.random() * 100);
  let loader = $('.loader');

  $(window).on('load', function () {
    body.addClass('disabled');
    loader.css('background-image', `url(${themeVars['home']}/wp-content/themes/secra/resources/img/SECRA_Preloader_Animated.svg?v=${version})`);
    window.setTimeout(function () {
      preloader.fadeOut();
      body.removeClass('disabled');
    }, 1500);
  })
}

export {
  initPreloader,
}
