import $ from 'jquery';

function triggerModal() {
  let modalTrigger = $('#modal-trigger');
  let modal = $('.modal')
  let modalClose = $('.modal__close')
  let modalContainer = $('.modal__container')

  function showModal() {
    modalTrigger.on('click', function () {
      modal.fadeIn();
      modalContainer.addClass('modal-active')
    })
  }

  function closeModal() {
    modalClose.on('click', function() {
      modal.fadeOut()
      modalContainer.removeClass('modal-active')
    })

    $(document).click(function (e) {
      if (e.target.closest('.modal__wrapper') === null && e.target.closest('#modal-trigger') === null) {
        modal.fadeOut()
        modalContainer.removeClass('modal-active')
      }
    })
  }

  showModal()
  closeModal()
}

export {
  triggerModal,
}
