import { animations } from "./modules/animations";
import { header } from "./modules/header";
import { initPreloader } from "./modules/preloader";
import { initLabel } from "./modules/form";
import { tabs } from "./modules/tabs";
import { slider } from "./modules/slider";
import { triggerModal } from "./modules/modal";

document.addEventListener("DOMContentLoaded", function() {
  tabs()
  setTimeout(function() {
    animations().init()
  }, 1000)
  initPreloader()
  initLabel()
  header().init()
  slider().init()
  triggerModal()
})
